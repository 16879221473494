.dash-grid-container {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  padding: 10px;
  grid-gap:10px;
}
.dash-grid-item {
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
  border: none;
  color: white;
}
.dgr-1{
  background-color: #20C5CB;
}
.dgr-2{
  background-color: #1672B9;
}
.dgr-3{
  background-color: #ff8aeb;
}
 

.dash-underfon{
  font-size: 18px;
}
.fa-dash{
  font-size: 100px;
  opacity: 0.5;
}