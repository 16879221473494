.card_view {
  width: 700px;
  height: 200px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 60px;
}

.CardContent {
  padding: 20px;
}

.search {
  border: none;
  outline: none;
  width: 100%;
  padding: 5px;
  color: #000;
  background-color: transparent;
  border: none;
}

.searchbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #e3e0e0;
}

.search-icon {
  /* margin-right: auto;*/
  color: #474747;
  cursor: pointer;
}
.sub-btn {
  background-color: #ffffff;
  color: #4da94d;
  border: none;
  background: transparent;
}
.sub-btn1 {
  background-color: #ffffff;
  color: #ff0000;
  border: none;
  background: transparent;
}
.sub-btn-clicked {
  border: none;
  padding: 10px 16px;
  background: rgb(204, 1, 1);
  color: white;
  border-radius: 5px;
  background: transparent;
}
.icon {
  font-size: 16px;
}
.g-button {
  color: #4da94d;
  border: none;
  background: transparent;
}
.g-button:focus {
  outline: none;
  background: transparent;
}
.r-button {
  color: #ff0000;
  border: none;
  background: transparent;
}
.btnpay{
  border: none;
  color: #45b6fe;
  background: transparent;
}
