td img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.ptr-img{
    width: 100px;
}

.btn-print{
    background-color: transparent;
    border-color: transparent;
    color: rgb(4, 142, 255);
    top: 10px;
    right: 20px;
    padding: 5px;
}
.btn-right{
    display: flex;
    justify-content: space-between;
}
