.container {
    margin: 0px;
    /* background-color: #f0f0f0; */
    display: block;
    width: 100%;
  }
  
  h1 {
    color: #474747;
  }
  
  table {
    border: 1px solid #a1a1a1;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding:0px 10px;
    text-align: left;
    border-bottom: 1px solid #a1a1a1;
    border-right: 1px solid #a1a1a1;
    color: #474747;
  }
  
  th {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #000;
    padding:0px 10px !important;
  }
  td{
    height: 5px;
  }
  .submit {
    color: #474747;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .paid {
    /* background-color: green;
    color: white;
    width: 60px;
    height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 20% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  
  .unpaid {
    /* background-color: red;
    color: white;
    width: 60px;
    height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 20% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  
  .yellow {
    background-color: rgb(243, 243, 55);
    
    width: 100px;
    height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 20% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    content: "Due Date";
  }
  
  /* input {
      border-color: transparent;
      width: auto;
      height: auto;
      color: white;
  } */
  
  .search {
    border: none;
    outline: none;
    width: 100%;
    padding: 5px;
    color: #000;
    background-color: transparent;
    border: none;
  }
  
  .searchbar {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0px 10px;
    border: 1px solid #e3e0e0;
  }
  
  .search-icon {
    /* margin-right: auto;*/
    color: #474747;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    th,
    td {
      text-align: center;
    }
  
    .submit {
      width: 80%;
    }
  }
  
  .Container {
    /* Your existing styles for the container go here */
    /* For example: */
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .table-container { 
    border-radius: 5px;
  }
  
  table {
    /* Your existing table styles go here */
    width: 100%;
    border-collapse: collapse;
  }
  
  table th,
  table td {
    border: 1px solid #f2f2f2;
    padding:0px 10px;
    text-align: left;
    border-bottom:1px solid #f2f2f2;
    
  }
  
  /* Style for the header row */
  table th {
    background-color: #fff;
    font-size: 14px !important;
  }
  tr{
      border:1px solid #f2f2f2;
  }
  
  /* Add a hover effect to table rows */
  table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .img-view-pg {
    width: 150px;
  }
  input[type="text"],input[type="password"],
  select {
      padding: 3px 10px !important;
  }
  .head-fon-mv{
      font-size: 18px !important;

  }
  .items-per-page {
    display: flex;
    align-items: center;
  }
  
  .items-per-page label {
    font-size: 16px; 
    margin: 0;
  }
  
  .items-per-page select {
    font-size: 16px;
    margin: 0 8px; 
    padding: 4px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
  }
  .dataTables_paginate {
    display: flex;
    justify-content: flex-end; 
  }
  
  .pagination {
    margin: 0; 
    list-style: none; 
  }
  
  .pagination li {
    display: inline-block;
    margin: 0;
  }
  
  .pagination button {
    cursor: pointer;
    padding: 6px 12px; 
    font-size: 14px; 
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .pagination button.disabled {
    cursor: not-allowed;
    background-color: #f8f9fa;
    border-color: #dee2e6;
    color: #6c757d;
  }


select:focus:not(:focus-visible) {
  outline: none;
}
.export {
  color: #158dfd;
  background-color: transparent;
  border: none;
  /* border-bottom-style: double; */
}
.export-btn{
  color: rgb(29, 111, 66);
}
