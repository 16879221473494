body {
  color: #000 !important;
  font-weight: bold;
}
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

h5 {
  padding: 4px;
  font-size: medium;
}

.registration-form1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 60%;
  max-width: 200px;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .form{
     background-color: #2f3542;
    color: #fff;
    width: 600px;
    height: 70vh;
    text-align: center;
   padding: 2rem; 
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column ;
    gap: 1rem;
    } */

/* .input{
      padding: 0.8rem 1rem;
      display: inline-block;
      width: 100%;
      outline: none;
      color: #fafafa;
      border-radius: .5rem;
      border: none;
      font-size: 1.2rem;
      background-color: #595d68;
    -webkit-border-radius:.5rem;
    -moz-border-radius:.5rem;
    -ms-border-radius:.5rem;
    -o-border-radius:.5rem;
    } */

.input {
  color: black;
  text-align: center;
  display: block;
}

.prtbtn {
  padding: 7px 15px;
  background-color: #0d9eff;
  color: #fff;
  border-radius: 5px;
  outline: 0;
  border: 0;
}

.loading {
  width: 80%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.loading span {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: 2px solid #fff;
  border-bottom: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  animation: _loading 1s linear infinite;
  color: #fafafa;
  -webkit-animation: _loading 1s linear infinite;
}

@keyframes _loading {
  0% {
    transform: rotate(360deg);
    -webkit-trasnform: rotate(360deg);
    -moz-trasnform: rotate(360deg);
    -ms-trasnform: rotate(360deg);
    -o-trasnform: rotate(360deg);
  }
}

.img {
  display: block;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 20%;
}

.qr-code {
  width: 65%;
  height: 70%;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  /* max-width: 50%;
            height: 100px; */
}

@media (max-width: 768px) {
  .uploaded-image {
    max-width: 80%;
  }

  .qr-code {
    max-width: 60%;
  }
}

.head-fon-card {
  font-size: 20px !important;
}

.input-fon-card {
  font-size: 20px !important;
  line-height: 24px !important;
}

.Card {
  width: 1000px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  padding: 30px;
}

.img-card-inner {
  width: 85px;
  /* height: 85px; */
}

.head-fon-card {
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: #604632;
}

.dot {
  /* justify-content: space-between; */
}
.id-topout {
  padding-top: 47px;
}
.id-leftout {
  padding-left: 10px;
}
.uploaded-image {
  width: 90px;
  height: 83px;
  object-fit: cover;
  border-radius: 5px;
}
.sub-btn-print{
  background-color: #0d9eff;
  color: #ffffff;
  border: none;
  padding: 5px;
  border-radius: 3px;
  margin: 5px;
}
.id-memid {
  font-size: 10px;
}
.id-memvalid {
  font-size: 7px;
}
.id-outmemname {
  padding-left: 45px;
  padding-top: 18px;
}
.id-inmemdet {
  padding-bottom: 15px;
  font-size: 11px;
  font-weight: bold;
}
.idqrout {
  display: flex;
  justify-content: end;
  padding-top: 10px;
  padding-right: 15px;
}
.prgap {
  height: 230px;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-btn-mem {
  padding: 7px 15px;
  color: white;
  border: none;
  background-color: #2471a3;
  border-radius: 5px;
}
.left-secprt {
  width: 130px;
}
.out-sectprt {
  padding-left: 30px;
}
.wid-dw {
  width: 300px;
}
.id-backcon {
  font-size: 11px;
  /* display: flex;
  justify-content: end;
  align-items: center;
  text-align: end;
  flex-direction: column; */
}
.id-qrin {
  padding-right: 15px;
  text-align: end;
  margin-top: 11px;
  font-weight: bold;
}
.qr-idcard {
  margin-bottom: 8px;
}
.qr-underdate {
  font-weight: bold;
  font-size: 11px;
}
@media print {
  /* Define styles for printing */
  .modal-content {
    width: 100%;
    /* Ensure the content fits the entire page width */
    margin: 0;
    /* Remove any margins for printing */
  }
}

@media print {
  .modal-content {
    /* ... other print styles ... */
    display: flex;
    justify-content: center;
    /* Center the content horizontally */
    align-items: center;
    /* Center the content vertically */
  }
}

.prt-company {
  width: 310px;
  padding-left: 37px;
  color: #471602;
  text-transform: uppercase;
}

.prt-memname {
  display: flex;
  justify-content: center;
  color: #471602;
}

.upper-sect-prt {
  padding-left: 37px;
}
.br-clr {
  color: #832424;
}

.main-con{
  background-color: #ddd;
  /* height: 550px; */
}


