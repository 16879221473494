

h1 {
  color: #474747;
}

table {
  border: 1px solid #f2f2f2;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  color: #474747;
}

th {
  background-color: #ddd;
  font-weight: bold;
  color: #000;
}

.submit {
  color: #474747;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}



.paid {

  background-color:#078f30;
    width: 100px;
    height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 20% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#ffffff;
   
}

.paid span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}


.unpaid {
  background-color:rgb(224, 48, 48);
  color: white;
  width: 100px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 20% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.yellow {
  background-color: rgb(243, 243, 55);
  color: white;
  width: 60px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 20% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
}



.search {
  border: none;
  outline: none;
  width: 100%;
  padding: 5px;
  color: #000;
  background-color: transparent;
  border: none;
}

.searchbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px 10px;
  border: 1px solid #e3e0e0;
}

.search-icon {
  color: #474747;
  cursor: pointer;
}

@media (max-width: 768px) {
  th,
  td {
    text-align: center;
  }

  .submit {
    width: 80%;
  }
}

/* .Container {

  max-width: 1200px;
  margin: 0 auto;
} */

.table-container { 
  border-radius: 5px;
  max-height: 500px; 
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #f2f2f2;
  padding:0px 10px;
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
}


/* Style for the header row */
table th {
  background-color: #fff;
  font-size: 14px !important;
}
tr{
    border:1px solid #f2f2f2;
}
td{
  font-size: 14px;
}

/* Add a hover effect to table rows */
table tbody tr:hover {
  background-color: #ddd;
}

.img-view-pg {
  width: 150px;
}
input[type="text"],input[type="password"],
select {
    padding: 3px 10px !important;
}
.head-fon-mv{
    font-size: 25px !important;
    display: flex;
    align-items: center;
    
}



.card-member {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 6px 7px 17px rgba(0, 0, 0, 0.2);
  width: 100%;

}

