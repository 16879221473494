body {
  height: 100px;
}


.login-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 6px 7px 17px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex: 1;
  padding: 20px;
  margin: auto;
}

.title {
  font-weight: bold;
  color: #000;
  font-size: 30px;
}

.label {
  display: flex;
  color: #474747;
  font-weight: bold;
}

.login-card form input {

  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  color: #474747;
  border-radius: 5px;
}


.login-btn-online {
  color: #007bff;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

}
.login-btn-online .arrow {
  margin-left: 5px;
}
.login-btn {
  background-color: #2b6f1f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100vh;
  width: 30vw;
  margin: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.card p {
  font-size: 18px;
  color: #474747;
  margin-bottom: 10px;
  line-height: 1.5;
}

.card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card ul li {
  font-size: 14px;
  color: #474747;
  margin-bottom: 5px;
}

.card .important {
  color: #007bff;
  font-weight: bold;
}
.login-content {
  text-align: center;
}
.online-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.online-btn:hover {
  background-color: #0056b3;
}

.left-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.main {
  background-image: url("../Images/login.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.arrow {
  justify-content: center;
  margin-top: 5px;
  margin-right: 2px;
}
.login-card form input:focus-visible{
  outline: none;
}
