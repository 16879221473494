input {
  color: #707070 !important;
}

label {
  font-weight: 100 !important;
  color: #474747;
  font-family: Inter !important;
}

input[type="text"],
input[type="password"],
select {
  padding: 3px 10px !important;
}


.form-control-payment {
  border: none !important;
  box-shadow: 0px 0px 4px 0px #b1b1b1 !important;
  border-radius: 5px;
}

.head-sectprt {
  display: flex;
  justify-content: space-between;
}

.prtheadfon {
  font-weight: bold;
  font-size: 24px;
  margin: 0 !important;
  display: flex;
  align-items: center;
}
.prtminfon{
  font-size: 14px;
}

.outerprt {
  padding: 30px;
}

.prtbtn {
  padding: 7px 15px;
  background-color: rgb(40, 233, 40);
  color: #fff;
  border-radius: 5px;
  outline: 0;
  border: 0;
}

