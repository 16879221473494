h1 {
  font-size: 40px !important;
  color: #242424 !important;
  margin: 0 !important;
}
.head-form {
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.form-control {
  border: none !important;
  box-shadow: 0px 0px 10px 0px #e7e7e7 !important;
}
select {
   font-size: 14px !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  box-shadow: 0px 0px 10px 0px #e7e7e7 !important;
}
label.form-label {
  font-weight: 500 !important;
  color: #474747;
}
.outer-con {
  padding:  30px 15px 15px 15px;

}
hr {
  border: 1px solid #cfcfcf !important;
}
input {
  color: #707070 !important;
}
.mem-fon {
  font-size: 28px;
  font-weight: 500;
}
.image-class-1 {
  width: 171px;
  height: 158px;
  border: 15px solid #fff;
}
.form-check {
  padding-left: 2.5rem !important;
}
.form-check-label {
  font-weight: 400;
}
.sub-btn-add {
  border: none;
  padding: 10px 16px;
  background: #4da94d;
  color: white;
  border-radius: 5px;
}
.close-btn{
    border: none;
    border-radius: 5px;
    color: white;
    background-color: red;
    padding: 10px 14px;
    margin-left: 10px;
}
.login-bttn{
  border: none;
  border-radius: 5px;
  background-color: #4da94d;
  padding: 10px 15px;
  color: #fff;
  /* margin-left: 90%; */
  text-align: end;
}

  .login1-benten {
    display: flex;
    justify-content: end;
}

.head-con{
  display: flex;
  justify-content: space-evenly;
}
.head-con h3{
  text-align: center;
}
.head-con h6{
  text-align: center;
  color: red;
}
h6{
  text-align: center;
  color: red !important;
  font-size: 15px !important;
}
.head-con h5{
  text-align: center;

}
.member-logo1 {
  height: 100px;
  width: 100px;
  float: right; 
}
.member-logo2{
  height: 100px;
  width: 100px;
  align-items:end; 
}
@media (max-width: 1000px) {
  .head-con h2 {
   font-size: 20px;
   padding-left: 20px;
  }

}
@media (max-width: 768px) {
  .head-con h2 {
   font-size: 12px;
  }
  .head-con h3 {
    font-size: 12px;
  }
  .head-con h6{
    font-size: 12px;
  }
  
}
@media (max-width: 510px) {
  .head-con h2 {
    font-size: 11px;
    
   }}
@media (max-width: 409px) {
  .head-con h2 {
    font-size: 8px;
    
   }
   .head-con h3 {
     font-size: 10px;
   }
   .head-con h6{
     font-size: 8px;
   }

}
@media (max-width :490px){
  .head-con h2 {
    padding-left:25px;
    
   }
}
@media (max-width :420px){
  .head-con h2 {
    padding-right:40px;
    font-size: 10px;
   }
}

@media (max-width :390px){
  .head-con h2 {
    padding-right:15px;
    
   }
}

@media (max-width: 500px) {
  .member-logo1 {
    height: 80px;
    width: 50px;
    float: right; 
  }
  .member-logo2{
    height: 80px;
    width: 50px;
    align-items:end; 
  }
}
@media (max-width: 360px) {
  .sub-btn {
    padding: 5px 9px;
    font-size:10px;
    width:120px
  }
}
@media(max-width:469px){
  .mem-fon{
    font-size:20px
  }
}
@media(max-width:369px){
  .mem-fon{
    font-size:16px
    
  }
}