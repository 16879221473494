
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
   
}

.container_dash {
    display: flex;
}

main {
    /* width: 100%; */
    padding: 20px;
}

.sidebar {
    background: #2471a3;
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
    /* line-height: 0px; */
}

.top_section {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 20px 0px;
    justify-content: space-around;
}

.logo {
    font-size:18px !important;
    color: white !important;
    cursor: pointer;
}

.bars {
    display: flex;
    font-size: 25px;
    cursor: pointer;
    justify-content: center;
}

.link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
}

.link:hover {
    background: #4f85a7;
    color: #fff;
    transition: all 0.5s;
}

.active {
    background: #154360;
    color: #fff;
}

.icon,
.link_text {
    font-size: 14px;
}

.content{
    width: auto;
    margin: auto;
    height: 100vh;
    overflow: auto;
    width:-webkit-fill-available;
}
